import React from 'react'
import './styles.css'

import separator from '../../assets/separator.svg'
import mark from '../../assets/mark.svg'
import imageMain from '../../assets/imageMain.png'
import dep from '../../assets/dep.png'
import depM from '../../assets/depM.png'
import arrow from '../../assets/arrow.svg'
import price from '../../assets/price.png'
import warranty from '../../assets/warranty.png'
import expert from '../../assets/expert.png'
import expertM from '../../assets/expertM.svg'
import expert4 from '../../assets/expert4.png'
import logo from '../../assets/logo.svg'

import a1 from '../../assets/list1/a1.png'
import a2 from '../../assets/list1/a2.png'
import a3 from '../../assets/list1/a3.png'
import a4 from '../../assets/list1/a4.png'
import a5 from '../../assets/list1/a5.png'

import a1m from '../../assets/list1/a1m.png'
import a2m from '../../assets/list1/a2m.png'
import a3m from '../../assets/list1/a3m.png'
import a4m from '../../assets/list1/a4m.png'
import a5m from '../../assets/list1/a5m.png'

import b1 from '../../assets/list2/a1.svg'
import b2 from '../../assets/list2/a2.svg'
import b3 from '../../assets/list2/a3.svg'
import b4 from '../../assets/list2/a4.svg'
import b5 from '../../assets/list2/a5.svg'
import b6 from '../../assets/list2/a6.svg'
import b7 from '../../assets/list2/a7.svg'

import c1 from '../../assets/list3/a1.png'
import c2 from '../../assets/list3/a2.png'
import c3 from '../../assets/list3/a3.png'
import c4 from '../../assets/list3/a4.png'
import c5 from '../../assets/list3/a5.png'
import c6 from '../../assets/list3/a6.png'
import c7 from '../../assets/list3/a7.png'

export default function Captura() {
    return (
        <>
















            <section className="bg2 min__height__bg d-flex flex-column justify-content-center align-items-center py-4 px-4">

                <div className="col-lg-5 p-5 image__expert__4__mob d-block d-lg-none">

                    <img src={expert4} alt="Imagem" className="img-fluid  " />

                </div>



                <img src={logo} alt="Imagem" className="img-fluid mb-4 logo" />


                <div className="col-lg-10 d-flex flex-row flex-wrap justify-content-center align-items-center mb-4">

                    <div className="col-lg-5 p-4 image__expert__4__mob">

                        <img src={expert4} alt="Imagem" className="img-fluid  d-lg-block d-none image__expert__4" />

                    </div>

                    <div className="col-lg-7">

                        <p className="font10 wg6 text__adjust__mob">TREINAMENTO COMPLETO</p>

                        <p className="font36 wg8 white">
                            O PASSO A PASSO PARA  <br className="d-lg-block d-none"></br>
                            CRIAR SUA LOJA ONLINE  <br className="d-lg-block d-none"></br>
                            QUE VENDE TODOS OS DIAS
                        </p>

                        <p className="font14 white mt-4 mb-4 text__adjust__mob">
                            É SUA OPORTUNIDADE DE TORNAR SUA MARCA RECONHECIDA  <br className="d-lg-block d-none"></br>
                            NO MERCADO, VENDER PARA TODO O BRASIL E SE DESTACAR  DA <br className="d-lg-block d-none"></br>
                            CONCORRÊNCIA MESMO QUE NÃO ENTENDA MUITO DE TECNOLOGIA
                        </p>

                        <div className="col-lg-11 mt-3">
                            <a href="https://pay.hotmart.com/X53533165L?off=73ij3e2p" className="btn btn-primary btn__cta wg7 w-100 bgl1">
                                QUERO UM NEGÓCIO QUE VENDE TODO DIA
                            </a>
                        </div>

                    </div>



                </div>


            </section >




















            <section className="bg1 d-flex flex-column justify-content-center align-items-center py-5 px-4">

                <p className="font36 wg8 blue">O QUE VOCÊ VAI APRENDER</p>

                <img src={separator} alt="Imagem" className="img-fluid" />


                <div className="col-lg-10 d-flex flex-row flex-wrap  justify-content-center align-items-center  pt-5">

                    <div className="col-lg-5">
                        <img src={a1} alt="Imagem" className="img-fluid d-lg-block d-none w-100" />
                        <img src={a1m} alt="Imagem" className="img-fluid d-block d-lg-none" />
                    </div>

                    <div className="col-lg-7 px-5 mapa__margin__adjust">
                        <p className="font24 wg7">
                            Criar e estruturar seu E-commerce
                        </p>
                        <p className="font18">
                            Aprenda do Absoluto zero o passo a passo para criar a sua primeira loja online, mesmo que você conheça pouco de internet ou tecologias.
                        </p>
                    </div>

                </div>

                <div className="col-lg-10 d-flex flex-row flex-wrap  justify-content-center align-items-center mt-5 pt-5">

                    <div className="col-lg-5 d-block d-lg-none">
                        <img src={a2m} alt="Imagem" className="img-fluid" />
                    </div>

                    <div className="col-lg-7 px-5 mapa__margin__adjust">
                        <p className="font24 wg7">
                            Estratégias de atração de clientes
                        </p>
                        <p className="font18">
                            Aprenda a atrair uma grande quantidade de pessoas qualificadas para sua loja e não dependa somente do Instagram para conseguir clientes.
                        </p>
                    </div>

                    <div className="col-lg-5">
                        <img src={a2} alt="Imagem" className="img-fluid d-lg-block d-none  w-100" />
                    </div>

                </div>

                <div className="col-lg-10 d-flex flex-row flex-wrap  justify-content-center align-items-center mt-5 pt-5">

                    <div className="col-lg-5">
                        <img src={a3} alt="Imagem" className="img-fluid d-lg-block d-none  w-100" />
                        <img src={a3m} alt="Imagem" className="img-fluid d-block d-lg-none" />
                    </div>

                    <div className="col-lg-7 px-5 mapa__margin__adjust">
                        <p className="font24 wg7">
                            Aumentar as vendas do E-commerce
                        </p>
                        <p className="font18">
                            Faça com que sua loja online transforme muito mais visitantes em clientes com as estratégias certas para aumentar sua conversão.
                        </p>
                    </div>

                </div>

                <div className="col-lg-10 d-flex flex-row flex-wrap  justify-content-center align-items-center mt-5 pt-5">

                    <div className="col-lg-5 d-block d-lg-none">
                        <img src={a4m} alt="Imagem" className="img-fluid" />
                    </div>

                    <div className="col-lg-7 px-5 mapa__margin__adjust">
                        <p className="font24 wg7">
                            Técnicas para aumentar o ticket médio
                        </p>
                        <p className="font18">
                            Aprenda como fazer para as pessoas comprarem mais de um produto de uma vez, aumentando assim e muito a sua margem de lucro.
                        </p>
                    </div>

                    <div className="col-lg-5">
                        <img src={a4} alt="Imagem" className="img-fluid d-lg-block d-none  w-100" />
                    </div>

                </div>

                <div className="col-lg-10 d-flex flex-row flex-wrap  justify-content-center align-items-center my-5 pt-5">

                    <div className="col-lg-5">
                        <img src={a5} alt="Imagem" className="img-fluid d-lg-block d-none w-100" />
                        <img src={a5m} alt="Imagem" className="img-fluid d-block d-lg-none" />
                    </div>

                    <div className="col-lg-7 px-5 mapa__margin__adjust">
                        <p className="font24 wg7">
                            Gerenciar o e-commerce como uma empresa
                        </p>
                        <p className="font18">
                            Você não será mais a dona de uma lojinha de Instagram, vai aprender a se tornar CEO do seu e-commerce e aumentar exponencialmente os seus resultados.
                        </p>
                    </div>

                </div>

            </section>


























            <section className="bg2 d-flex flex-column justify-content-center align-items-center py-5 px-4">

                <p className="font36 wg8 white">PARA QUEM É ESSE CURSO</p>

                <div className="col-lg-9 d-flex flex-row flex-wrap justify-content-center align-items-center">

                    <div className="col-lg-6  mt-4">

                        {
                            list1.map((element, index) => {
                                return (
                                    <div className="col-lg-12 d-flex flex-row mb-4">

                                        <div className="col-lg-1">
                                            <img src={mark} alt="Imagem" className="img-fluid d-lg-block d-none" />
                                        </div>
                                        <div className="col-lg-10 px-4">

                                            <img src={mark} alt="Imagem" className="img-fluid d-block d-lg-none mb-3 m-auto" />
                                            <p className="font18 white wg4 mapa__text__centered">
                                                {element.title}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>

                    <div className="col-lg-6 mt-4">

                        <img src={imageMain} alt="Imagem" className="img-fluid " />

                    </div>

                </div>

            </section >
































            <section className="bg1 d-flex flex-column justify-content-center align-items-center py-5 px-4">

                <p className="font36 wg8 blue text-center">
                    DOMINE AS 7 FASES DE UM <br className="d-lg-block d-none"></br>
                    E-COMMERCE DE SUCESSO
                </p>

                <img src={separator} alt="Imagem" className="img-fluid" />

                <div className="col-lg-8 d-flex flex-column justify-content-center align-items-center">

                    {
                        list2.map((element, index) => {
                            return (
                                <div className="col-lg-12 mapa__big__card shadow d-flex flex-row flex-wrap justify-content-center align-items-center mt-5">

                                    <div className="col-lg-4 p-5 mapa__big__icon">
                                        <img src={element.img} alt="Imagem" className="img-fluid w-100 " />
                                    </div>
                                    <div className="col-lg-8">

                                        <p className="mapa__phase font18 wg7 blue">
                                            {element.phase}
                                        </p>
                                        <p className="mapa__tile font24 wg8 ">
                                            {element.title}
                                        </p>
                                        <p className="mapa__desc font16">
                                            {element.desc}
                                        </p>

                                    </div>

                                </div>
                            )
                        })
                    }

                </div>

            </section >



















            <section className="bg2 d-flex flex-column justify-content-center align-items-center py-5 px-4">

                <p className="font36 wg8 white text-center">
                    O QUE MINHAS ALUNAS  <br className="d-lg-block d-none"></br>
                    ESTÃO FALANDO:
                </p>

                <div className="col-lg-9 d-flex flex-row flex-wrap justify-content-center align-items-center mt-4">
                    {
                        videos.map((element, index) => {
                            return (
                                <div className="col-lg-6 p-4 video__adjust">
                                    <iframe src={element.video}
                                        controls="0"
                                        width="100%"
                                        height="250"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        style={{ borderRadius: "6px" }}
                                    >

                                    </iframe>
                                </div>)
                        })
                    }

                </div>

                <div className="col-lg-9 d-flex flex-row flex-wrap justify-content-center align-items-center my-4">

                    <img src={dep} alt="Imagem" className="img-fluid d-lg-block d-none" />
                    <img src={depM} alt="Imagem" className="img-fluid d-block d-lg-none" />

                </div>


                <div className="col-lg-5 my-4">
                    <a href="https://pay.hotmart.com/X53533165L?off=73ij3e2p" className="btn btn-primary btn__cta wg7 w-100 bgl1">
                        QUERO UM NEGÓCIO QUE VENDE TODO DIA
                    </a>
                </div>

            </section >



































            <section className="bg1 d-flex flex-column justify-content-center align-items-center py-5 px-4">

                <p className="font36 wg8 blue text-center">
                    O QUE VOCÊ GANHA DE PRESENTE <br className="d-lg-block d-none"></br>
                    AO SE INSCREVER NO MAPA
                </p>

                <img src={separator} alt="Imagem" className="img-fluid" />


                <div className="col-lg-10 d-flex flex-row flex-wrap justify-content-around align-items-start ">

                    {
                        list3.map((element, index) => {
                            return (
                                <div className="col-lg-5 mapa__big__card__2 shadow d-flex flex-column justify-content-start align-items-center text-center p-5">

                                    <img className="img-fluid bonus__image" alt="image" src={element.img} />

                                    <p className="font24 wg7 mapa__big__card__2__title">
                                        {element.title}
                                    </p>
                                    <p className="font16 wg3">
                                        {element.desc}
                                    </p>

                                </div>
                            )
                        })
                    }


                </div>

            </section >





































            <section className="bg2 d-flex flex-column justify-content-center align-items-center py-5 px-4">

                <div className="col-lg-5 d-flex flex-column flex-wrap justify-content-center align-items-center price__card my-5">

                    <img src={price} alt="Imagem" className="img-fluid price__margin" />

                    <a href="https://pay.hotmart.com/X53533165L?off=73ij3e2p" className="btn btn-primary btn__cta wg7 w-100 bgl1">
                        QUERO UM NEGÓCIO QUE VENDE TODO DIA
                    </a>

                </div>

            </section >








            <section className="bg1 d-flex flex-column justify-content-center align-items-center py-5 px-4">

                <p className="font36 wg8 blue text-center mb-5">
                    GARANTIA
                </p>


                <div className="col-lg-8 d-flex flex-row flex-wrap justify-content-between align-items-start card__warranty">


                    <div className="col-lg-3 d-flex flex-row flex-wrap justify-content-center align-items-center adjust__mob__100">

                        <img src={warranty} alt="Imagem" className="img-fluid w-100" />

                    </div>

                    <div className="col-lg-8 text__adjust__mob">

                        <p className="warraty__text font18 ">
                            Se você sentir que o Mapa do E-commerce de Sucesso não é para você, eu assumo todo o risco e basta me enviar um email em até 7 dias da data da sua compra que eu devolvo 100% do seu investimento.
                        </p>

                    </div>

                </div>


                <p className="font36 wg8 blue text-center mt-5">
                    O RISCO É ZERO!
                </p>

            </section >




























            <section className="bg2 d-flex flex-column justify-content-center align-items-center py-5 px-4">


                <div className="col-lg-9 d-flex flex-row flex-wrap justify-content-center align-items-center">

                    <div className="col-lg-4  mt-4">

                        <img src={expert} alt="Imagem" className="img-fluid" />

                    </div>

                    <div className="col-lg-1  mt-4">

                    </div>

                    <div className="col-lg-6 mt-4 text__adjust__mob">

                        <p className="font36 wg8 white ">
                            Sobre sua Mentora
                        </p>

                        <p className="font16 wg3 white">
                            Laís atua como empresária desde 2013 na Nós e o Davi, uma empresa que ela criou nativamente no mercado digital e que se destaca no mercado de sustentabilidade.
                        </p>

                        <p className="font16 wg3 white">
                            Nesses 8 anos leva a mensagem para todos os estados do Brasil e para alguns países da América latina como Chile e Argentina e fatura 6 dígitos por mês, tudo isso, com estratégias 100% digitais.
                        </p>

                        <p className="font16 wg3 white">
                            Desde 2016 se dedica a dar força a outras mulheres e negócios, ensina tudo que aplico e vive da sua empresa.
                        </p>

                    </div>

                </div>

            </section >























            <section className="bg1 d-flex flex-column justify-content-center align-items-center py-5 px-4">

                <p className="font36 wg8 blue text-center mb-5">
                    Dúvidas frequentes
                </p>


                <div className="col-lg-7 d-flex flex-column justify-content-center align-items-start">


                    <div class="accordion-item w-100">
                        <h2 class="accordion-header bg3" id="headingFive">
                            <button class="py-4 bg3 wg6 white accordion-button text-start" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                                1. Tenho pouco tempo, vou conseguir fazer o curso?
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse show" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p className="c-04">
                                    Sim, pois as aulas ficam gravadas na plataforma e você pode assistir a hora que desejar, por quantas vezes quiser dentro do período de acesso de 1 ano.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item w-100">
                        <h2 class="accordion-header bg3" id="headingSix">
                            <button class="py-4 bg3 wg6 white accordion-button text-start" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                2. Eu quero ter minha loja online, mas não sei o que vender. O curso é para mim?
                            </button>
                        </h2>
                        <div id="collapseSix" class="accordion-collapse collapse show" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p className="c-04">
                                    Sim, tem aulas dentro do curso que vai te ajudar a fazer sua escolha de forma assertiva, de qual produto e área abrir a sua loja online
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item w-100">
                        <h2 class="accordion-header bg3" id="headingSeven">
                            <button class="py-4 bg3 wg6 white accordion-button text-start" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                3. E se eu não puder assistir as aulas ao vivo?
                            </button>
                        </h2>
                        <div id="collapseSeven" class="accordion-collapse collapse show" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p className="c-04">
                                    Todas as aulas serão gravadas e ficarão disponíveis na área de membros.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item w-100">
                        <h2 class="accordion-header bg3" id="headingOne">
                            <button class="py-4 bg3 wg6 white accordion-button text-start" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                4. Por quanto tempo terei acesso ao conteúdo?
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p className="c-04">
                                    Você terá acesso total à área de membros por 1 ano. É tempo de sobra para revisar o conteúdo quantas vezes quiser.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item w-100">
                        <h2 class="accordion-header bg3" id="headingTwo">
                            <button class="py-4 bg3 wg6 white accordion-button text-start" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                5. Quais as formas de pagamento?
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p className="c-04">
                                    Parcelado em até 12x no cartão de crédito (pode dividir em até 2 cartões), à vista no boleto e no Pix e Paypal
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item w-100">
                        <h2 class="accordion-header bg3" id="headingThree">
                            <button class="py-4 bg3 wg6 white accordion-button text-start" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                6. Como funciona a garantia?
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p className="c-04">
                                    Após efetuar a sua compra, você terá 7 dias de garantia incondicional, ou seja, dentro do período se não gostar, é só pedir o seu dinheiro de volta.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item w-100">
                        <h2 class="accordion-header bg3" id="headingFour">
                            <button class="py-4 bg3 wg6 white accordion-button text-start" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                7. Consigo assistir pelo celular?
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p className="c-04">
                                    Sim! Você pode assistir tudo pelo navegador do celular ou pelo aplicativo Sparkle. Pode inclusive assistir aulas offline pelo App.
                                </p>
                            </div>
                        </div>
                    </div>


                </div>


            </section >






            <section className="bg3 d-flex flex-column justify-content-center align-items-center py-5 px-4">

                <img src={logo} alt="Imagem" className="img-fluid" />

            </section >



        </>
    )
}



const videos = [
    {
        video: "https://www.youtube.com/embed/Hu8veNqCoFg"
    },
    {
        video: "https://www.youtube.com/embed/uNMl99XjpzE"
    },
    {
        video: "https://www.youtube.com/embed/GpFrZsH-h7M"
    },
    {
        video: "https://www.youtube.com/embed/CuXDIsoaqqU"
    },
]


const list1 = [
    {
        title: "Para quem se sente presa ao negócio atual e deseja ter um negócio que vende 24 horas por dias, 7 dias por semana, sem depender de você."
    },
    {
        title: "Você que já sabe que não dá mais pra ficar de fora da internet e quer dar os primeiros passos para abrir uma loja online de sucesso."
    },
    {
        title: "Você que quer começar a empreender para ter liberdade e sabe que a internet é o melhor lugar para se começar."
    },
    {
        title: "Você que já tem um E-commerce, mas quer escalar o seu negócio."
    },
]

const list2 = [
    {
        img: b1,
        phase: "Fase 1",
        title: "Construindo seu negócio de sucesso:",
        desc: "Aprenda a construir um negócio que te dê liberdade de tempo e de dinheiro, como fazer um planejamento estratégico e a usar a concorrência a seu favor."
    },
    {
        img: b2,
        phase: "Fase 2",
        title: "Começando a faturar bem na internet:",
        desc: "Aprenda como se destacar no mercado e entenda a jornada de compra do cliente para aumentar suas vendas"
    },
    {
        img: b3,
        phase: "Fase 3",
        title: "Criando sua plataforma de vendas:",
        desc: "Monte uma estrutura perfeita para fechar vendas, passo a passo desde a escolha do domínio até a construção de um checkout eficiente."
    },
    {
        img: b4,
        phase: "Fase 4",
        title: "Seu cliente preparado para comprar:",
        desc: "Entenda os motivos que seus clientes não compram e utilize as estratégias para acabar com todas as dúvidas e impedimentos do seu cliente e prepare seu cliente para ficar pronto para comprar."
    },
    {
        img: b5,
        phase: "Fase 5",
        title: "Atraindo clientes para sua loja online:",
        desc: "Como atrair o cliente perfeito para você em todos os canais da internet, crie sua biblioteca de conteúdo para nunca mais ficar sem ideias do que postar, seja encontrada no Google e tenha um passo a passo para montar campanhas de vendas."
    },
    {
        img: b6,
        phase: "Fase 6",
        title: "Entregando o combinado:",
        desc: "Toda parte de logística descomplicada para você. Encante os seus clientes e ainda tenha uma gestão eficiente de pedidos e estoque."
    },
    {
        img: b7,
        phase: "Fase 7",
        title: "Otimizando sua loja online:",
        desc: "Como ler os dados do seu e-commerce, entenda o funil de vendas e saiba como aumentar seu faturamento e ainda tenha acesso ao checklist do e-commerce que vende bem."
    },
]

const list3 = [
    {
        img: c1,
        title: "Como precificar corretamente",
        desc: "Aprenda como precificar e ter uma margem de lucro maior, sem ter clientes reclamando do preço."
    },
    {
        img: c2,
        title: "Como fazer uma gestão financeira simples que funciona",
        desc: "Aprenda uma maneira simples de realizar a gestão financeira da sua loja online para manter ela sempre lucrativa."
    },
    {
        img: c3,
        title: "Como fazer anúncios para sua loja online",
        desc: "Saiba como anunciar para o seu cliente ideal e aumentar as suas vendas."
    },
    {
        img: c4,
        title: "Tirando fotos que vendem",
        desc: "Boas fotos mudam completamente a percepção de valor que seu cliente tem, aprenda a usar os melhores ângulos e iluminação para mostrar o melhor dos seus produtos, mesmo que você não entenda nada sobre fotografia."
    },
    {
        img: c5,
        title: "Beabá da Contabilidade",
        desc: "Aprenda o básico a realizar a parte contábil da sua loja e tenha um negócio sólido e seguro."
    },
    {
        img: c6,
        title: "E-commerce Express",
        desc: "Aulas práticas e objetivas para você criar do zero sua loja online e colocar no ar em apenas 15 dias. Especialmente para você que tem pouco tempo e mesmo assim deseja construir um negócio lucrativo e de sucesso."
    },
    {
        img: c7,
        title: "Acompanhamento ao vivo",
        desc: "Você terá um acompanhamento exclusivo que será sempre ao vivo, para você ter um resultado ainda melhor com o curso."
    },
]