import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Captura from './pages/Captura'
// import Obrigado from './pages/Obrigado'
// import Principal from './pages/Principal'

function Routes() {

  return (
    <Router>

      <Switch>

        <Route exact path="/" component={Captura} />
        <Route exact path="/mapa-do-ecommerce" component={Captura} />
        <Route exact path="/mapa-do-ecommerce-de-sucesso" component={Captura} />
        {/* <Route exact path="/obrigado" component={Obrigado} />
        <Route exact path="/principal" component={Principal} /> */}

      </Switch>

    </Router>
  );
}

export default Routes;
